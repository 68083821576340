import Quill from 'quill';
document.addEventListener("DOMContentLoaded", function (event) {
    if (document.querySelector('#site-editor-container')) { 
        var siteQuill = new Quill('#site-editor-container', {
            modules: {
            toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }],
                [{ size: [] }],
                [
                'bold', 'italic', 'underline', 'strike',
                { 'script': 'super'},
                { 'script': 'sub' },
                'code', 'link'
                ],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ align: ['center', 'right', 'justify', false] }],
                [{ indent: '-1'}, { indent: '+1' }]
        
        
            ],
            },
            theme: 'snow'
        });

        var bioQuill = new Quill('#bio-editor-container', {
            modules: {
            toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }],
                [{ size: [] }],
                [
                'bold', 'italic', 'underline', 'strike',
                { 'script': 'super'},
                { 'script': 'sub' },
                'code', 'link'
                ],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ align: ['center', 'right', 'justify', false] }],
                [{ indent: '-1'}, { indent: '+1' }]
        
        
            ],
            },
            theme: 'snow'
        });

        var cvQuill = new Quill('#cv-editor-container', {
            modules: {
            toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }],
                [{ size: [] }],
                [
                'bold', 'italic', 'underline', 'strike',
                { 'script': 'super'},
                { 'script': 'sub' },
                'code', 'link'
                ],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'unordered' }],
                [{ align: ['center', 'right', 'justify', false] }],
                [{ indent: '-1'}, { indent: '+1' }]
        
        
            ],
            },
            theme: 'snow'
        });

        document.querySelector('#site-form').onsubmit = function () {
            var body = document.querySelector('input[class=sitetext-content]');
            body.value = siteQuill.root.innerHTML
        };

        document.querySelector('#bio-form').onsubmit = function () {
            var body = document.querySelector('input[class=biotext-content]');
            body.value = bioQuill.root.innerHTML
        };

        document.querySelector('#cv-form').onsubmit = function () {
            var body = document.querySelector('input[class=cvtext-content]');
            body.value = cvQuill.root.innerHTML
        };
    }
});




export default Quill;
